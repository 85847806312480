export const WorkData = [
	{
		title: "HydraSwap",
		desc: "Extended backend features and rust contract side",
		tech: ["Rust", "Anchor", "Hydra Swap"],
		gitlink: "//github.com/Pappyjay23/the-movie-hub",
		site: "//hydraswap.io/",
	},
	{
		title: "Degen Taxi",
		desc: "One kind of Sol crash game",
		tech: ["Web3", "Anchor", "Typescript","Next.js"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//degentaxi.io",
	},
	{
		title: "BASC NFT Staking V2",
		desc: "This is an individual and matching pair staking on Solana and Users can stake BASC NFTs and earn $DAB token as reward.I wrote the staking contract and web3 integration.",
		tech: ["Next.js", "NFT staking", "Anchor","Web3"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//stakingv2.babyapesocialclub.com",
	},
	{
		title: "Poly186",
		desc: "Built the website from scratch and worked as a member from the initial stage of the project.Made P8 token and liquidity pool based on Gnosis chain. Was building a Polyverse platform.",
		tech: ["Polyverse platform", "Gnosis chain", "P8 token"],
		gitlink: "//github.com/Pappyjay23/netflix-clone",
		site: "//www.poly186.io",
	},
	{
		title: "Divvy (Fullstack & P2E Game)",
		desc: "Built frontend with react/typescript and solana web3.js. Developed moonshot game backend with web socket and redis and also integrated usdc and $divvy token",
		tech: ["React.js", "Typescript","Web3","Web Socket","Redis"],
		gitlink: "//www.github.com/Pappyjay23/budget-app",
		site: "//divvy.bet",
	},
	{
		title: "LeanScale",
		desc: "Built the Ecommerce platform using Next.js and API integration.Led the front-end team as lead developer, Managed source code using Git and CI/CD - pipeline.",
		tech: ["Next.js"],
		gitlink: "//www.github.com/Pappyjay23/instablog",
		site: "//www.leanscale.com/",
	},
	{
		title: "Carefizz ",
		desc: "Health & Fitness site and they bring the most popular entertainment content directly to user mobile devices.",
		tech: ["Vue", "Typescript","Node.js", "Mongoose"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//lv.carefizz.com",
	},
	{
		title: "Hike Wild Montana",
		desc: "People and wildlife flourish because public lands and waters are wild and connected.",
		tech: ["React.js", "Redux", "Mapbox GL"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//wildmontana.org/hike",
	},
	{
		title: "Events Smarter",
		desc: "Events Smarter welcomes users into a user-friendly experience ",
		tech: ["PHP", "Vue", "Laravel","Mysql"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//www.eventssmarter.com",
	},
	{
		title: "Mad Paws Dinner Bowl ",
		desc: "Australia's one-stop shop for healthy, quality dog food delivered to customer’s doors.",
		tech: ["Next.js", "TailwindCss","Node.js", "Mongoose"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//madpawsdinnerbowl.com.au",
	},
	{
		title: "YAKU Hub",
		desc: "Yaku Hub is your access pass to a smarter platform that delivers value to the ecosystem as a whole.Worked on the front-end part using Next.js and integrated Web3.",
		tech: ["Next.js", "TailwindCss", "Typescript","Web3"],
		gitlink: "//github.com/Pappyjay23/Digitaly",
		site: "//www.yaku.ai",
	},
];
